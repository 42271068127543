/* Custom Theme Styles */
// 1. Base Styles
// 2. Page Template
// 3. Navigation and Logo
// 4. Site Header
// 5. Site Footer
// 6. Page Element Structure and Style
// 7. Pill Buttons
// 8. Edit Mode Fixes
// 9. Theme Form Styles
// 10. Responsive Grid Structure


// 1. Base Styles
html { box-sizing: border-box; }
* { box-sizing: inherit; }

// Game SHow Field of Play
#FOP { box-sizing: content-box; }

html,
body {
  padding: 0;
  margin: 0;
  border: none;
  background-color: transparent;
}

#pageContentContainer {
  background: #fff;
}

.layoutContainer { padding: 0; }


// 5. Site Footer
.snFooterContainer{
  width:100%;
  max-width:$maxWidth;
  margin:0 auto;
  background-color:#eee;
}
#siteFooter {
  @extend %clearfix;
  display: block;
  margin: auto;
  padding: 10px 20px;
  position: relative;
  vertical-align: bottom;
  width: 100%;
  font-size: .8em;
  clear:both;
  > ul {
    padding: 0;
    @include displayCenter($maxWidth);
    li {
      display: inline-block;
      padding:0 .2em;
      min-height:20px;
      a { display: inline-block; }
      &:hover { display: inline-block; }
    }
  }
}
#siteFooter li#poweredByNGIN{
	a{
		display:block;
		width:120px;
		height:20px;
		position:relative;
		&:after{
			content:url($asset-path-for+'/logo_images/logo_black.svg');
			display:block;
			position:absolute;
			top:4px;
			left:0;
			width:100%;
		}
		img{
			display:none;
		}
	}
}

@media only screen and (max-width: 1024px) {
  .snFooterContainer{
    max-width:1024px;
  }
}

// 6. Page Element Structure and Style
.pageEl {
   @extend %clearfix;
  .pageElement {
    width: 100%;
    margin: 15px 0;
    float:left;
    clear:both;
  }
  .widgetElement{
    .widgetHierarchy{
      overflow-x: auto;
      overflow-y: hidden;
    }
  }

  /* Text Block Element */
  .textBlockElement {

    .leftTextImage,
    .originalTextImage,
    .rightTextImage {
      width: 100%;
      br {
        display: none;
      }
      .cutline {
        width: 100%;
        margin: 0 auto;
      }
    }

    img {
      width: auto;
      max-width: 100%;
      display: block;
      margin: auto;
    }
  }

  //jwplayer
  .video .jwplayer {
    width: 100% !important;
    height: 100% !important;
  }
  .jwplayer {
    width: 100% !important;
  }

  /* Content Tabs */
  .tabbedElement {
    ul.contentTabs {
      border: none;

      li {
        span {
          a { display: block; }
        }
      }
    }
    .tabContainer {
      .current {
        // There is an inline width set here.
        // Need to uddate tabElement to remove.
        width: 100% !important;
      }
    }
  }
}

// 7. Pill Buttons
#PlayerStatsDiv .sport-tools:nth-child(even) ul.pill-buttons li,
#TeamStatsDiv .sport-tools:nth-child(even) ul.pill-buttons li {
  &.selected a {
    color: $link-color;
    border-bottom: 3px solid $link-color;
    background-color: transparent;
  }

  a {
    background-color: transparent;
    border: 0;
  }
}

// 8. Edit Mode Fixes
.edit_mode{
  .contentTabs.rapidTabsConstruction{
    li{
      span{
        a{
          color:#666;
          background:transparent;
          border-right: none;
        }
      }
    }
    .selected{
      span a{
        background:#FCCD22;
        color:#333;
      }
    }
  }
}

// 9. Theme Form Styles
.pageElement input[type="text"], .pageElement input[type="password"] {
  width:100%;
  max-width:480px;
  @include text-field-style;
}
.pageElement textarea{
  @include text-field-style;
}
.pageElement select {
	@include dropdown-style;
}

// 10. Responsive Grid Structure
@media only screen and (max-width: 767px) {
  .oneColumn .column1, .layoutContainer .oneColumn .column1{
    width: 100%;
  }

  .twoColumn2575 .column2, .twoColumn7525 .column1 {
    width: 100%;
  }

  .twoColumn6633 .column1, .twoColumn3366 .column2, .layoutContainer .twoColumn6633 .column1, .layoutContainer .twoColumn3366 .column2{
    width: 100%;
  }

  .twoColumn5050 .column1, .twoColumn5050 .column2, .threeColumn502525 .column1, .threeColumn255025 .column2, .threeColumn252550 .column3, .layoutContainer .twoColumn5050 .column1, .layoutContainer .twoColumn5050 .column2, .layoutContainer .threeColumn502525 .column1, .layoutContainer .threeColumn255025 .column2, .layoutContainer .threeColumn252550 .column3{
    width: 100%;
  }

  .threeColumn333333 .column1, .twoColumn6633 .column2, .twoColumn3366 .column1, .threeColumn333333 .column2, .threeColumn333333 .column3, .layoutContainer .threeColumn333333 .column1, .layoutContainer .twoColumn6633 .column2, .layoutContainer .twoColumn3366 .column1, .layoutContainer .threeColumn333333 .column2, .layoutContainer .threeColumn333333 .column3{
    width: 100%;
  }

  .twoColumn2575 .column1, .twoColumn7525 .column2,
  .threeColumn502525 .column2, .threeColumn502525 .column3,
  .threeColumn255025 .column1, .threeColumn255025 .column3,
  .threeColumn252550 .column1, .threeColumn252550 .column2,
  .fourColumn25252525 .column1, .fourColumn25252525 .column2,
  .fourColumn25252525 .column3, .fourColumn25252525 .column4,
  .layoutContainer .threeColumn502525 .column2, .layoutContainer .threeColumn502525 .column3,
  .layoutContainer .threeColumn255025 .column1, .layoutContainer .threeColumn255025 .column3,
  .layoutContainer .threeColumn252550 .column1, .layoutContainer .threeColumn252550 .column2,
  .layoutContainer .fourColumn25252525 .column1, .layoutContainer .fourColumn25252525 .column2,
  .layoutContainer .fourColumn25252525 .column1, .layoutContainer .fourColumn25252525 .column2
  {
    width: 100%;
  }
  .width25, .width33, .width50, .width66, .width75, .width100 {
    width:100%;
  }
  .col-xs-1, .col-sm-1, .col-md-1, .col-lg-1, .col-xs-2, .col-sm-2, .col-md-2, .col-lg-2, .col-xs-3, .col-sm-3, .col-md-3, .col-lg-3, .col-xs-4, .col-sm-4, .col-md-4, .col-lg-4, .col-xs-5, .col-sm-5, .col-md-5, .col-lg-5, .col-xs-6, .col-sm-6, .col-md-6, .col-lg-6, .col-xs-7, .col-sm-7, .col-md-7, .col-lg-7, .col-xs-8, .col-sm-8, .col-md-8, .col-lg-8, .col-xs-9, .col-sm-9, .col-md-9, .col-lg-9, .col-xs-10, .col-sm-10, .col-md-10, .col-lg-10, .col-xs-11, .col-sm-11, .col-md-11, .col-lg-11, .col-xs-12, .col-sm-12, .col-md-12, .col-lg-12{
    padding-left:($bootstrap-padding-sm * 1px);
    padding-right:($bootstrap-padding-sm * 1px);
  }
}

// As soon as layout container starts to squish,
// make small columns bigger until they become full
// width for mobile (768px)
@media (min-width: 768px) and (max-width: 999px) {
  .layout-50-25-25,
  .layout-25-25-50 {
    .col-md-3 { width: 50%; }
    .col-md-6 { width: 100%; }
  }

  .layout-25-50-25 {
    .col-md-3,
    .col-md-6 { width: 33.3333% }
  }
}


// TEMP FIXES FOR BROKEN NAV
.theme-nav-style-slide > * { width: 100%; }

// Other background styles are rendered inline (based on user settings)
.site-background { position: absolute; }
